import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ThemeBreakpoints } from '../theme';

export const useStyles = makeStyles<Theme>(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    outline: 'none',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1.5),
    background: theme.palette.common.white,
    overflow: 'scroll',
    maxHeight: theme.spacing(90),
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      width: '80%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '55%',
    },
  },
}));
