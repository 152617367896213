import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ThemeBreakpoints } from '@wr/web-ui';

import { colors } from '@/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  title: {
    color: colors.sendwaveBlack,
    marginBottom: theme.spacing(3.75),
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      fontSize: theme.spacing(3.5),
    },
  },
  questionContainer: {
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  questionLink: {
    'color': colors.sendwaveTeal,
    'textDecoration': 'underline',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '& .MuiSvgIcon-root': {
      display: 'none',
      [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
        display: 'inline-block',
      },
    },
  },
  modal: {
    '& .MuiBox-root': {
      overflow: 'auto !important',
    },
  },
}));
