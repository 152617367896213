import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { Container as UIContainer } from '@wr/web-ui';

import { buildContentfulSidekickAttributes } from '@/utils';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';
import { useStyles } from './faq-module-item.styles';

export const FaqModuleItem: React.FC<contentfulSchema.FaqModuleItem> = ({
  sys,
  __typename,
  name,
  title,
  description,
}) => {
  const classes = useStyles();
  return (
    <>
      <section
        className={classes.container}
        id={name || undefined}
        {...buildContentfulSidekickAttributes(sys.id, __typename, name)}
      >
        <UIContainer>
          {title && (
            <Typography component="h2" variant="h2" gutterBottom>
              {title}
            </Typography>
          )}
          {description && (
            <RichTextContentRenderer
              json={description.json}
              links={description.links as RichTextProps['links']}
              options={{
                [BLOCKS.PARAGRAPH]: {
                  paragraph: true,
                  variant: 'body2',
                },
                [BLOCKS.LIST_ITEM]: {
                  variant: 'body2',
                },
              }}
            />
          )}
        </UIContainer>
      </section>
    </>
  );
};
