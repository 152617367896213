import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { REGION_CODES, SENDWAVE } from '@/constants';
import { useIntlMessages, useSendAndReceiveCountries } from '@/hooks';
import { interpolate } from '@/utils';

import { PageMetadataProps } from './page-metadata.types';
import {
  generateSchemaMarkup,
  getActiveRegionCode,
  getCanonicalUrl,
  getLanguageAlternates,
} from './page-metadata.utils';

export const PageMetadataComponent: React.FC<PageMetadataProps> = ({
  title,
  description,
  noIndex,
  originUri = 'https://localhost:3001',
  pathname,
  pageLinksWithRegionsAndSlugs,
  regions,
  analyticsPageType,
  regionCode,
  organizationSchema,
  modules,
}) => {
  const messages = useIntlMessages();
  const { locale = DEFAULT_LANGUAGE, query } = useRouter();
  const { sendCountries } = useSendAndReceiveCountries();

  const canonical = getCanonicalUrl({
    originUri,
    locale,
    query,
    pathname,
    regions,
  });
  const formattedPathname = pathname !== '/' ? pathname : '';
  const hasQueryParams = Object.keys(query).length > 0;

  const languageAlternates = getLanguageAlternates({
    originUri,
    pathname: formattedPathname,
    noIndex,
    regions,
    analyticsPageType,
    sendCountries,
  });

  const availableLanguageAlternates = useMemo(() => {
    const formattedActiveUrls = pageLinksWithRegionsAndSlugs?.map(
      (link: { slug: string }) => ({
        href: link.slug ? `${originUri}/${link.slug}` : `${originUri}`,
      }),
    );

    return languageAlternates?.filter(lang => {
      return formattedActiveUrls?.some(
        (url: { href: string }) => url.href === lang.href,
      );
    });
  }, [languageAlternates, originUri, pageLinksWithRegionsAndSlugs]);

  const activeRegion =
    regionCode?.length && getActiveRegionCode(regionCode, locale);

  const formattedTitle = title && interpolate(title.trim(), messages);

  const schemaMarkups = generateSchemaMarkup(analyticsPageType, {
    organizationSchema,
    modules,
  });

  const renderActiveRegion = () => {
    const pipedSwSuffix = `| ${SENDWAVE}`;
    const hasPipedSwSuffix = formattedTitle?.includes(pipedSwSuffix);

    if (activeRegion) {
      if (activeRegion !== REGION_CODES.en) {
        if (!hasPipedSwSuffix) {
          return ` ${pipedSwSuffix} ${activeRegion}`;
        } else {
          return activeRegion;
        }
      } else {
        if (hasPipedSwSuffix) {
          return '';
        }

        return pipedSwSuffix;
      }
    }

    return '';
  };

  return (
    <Head>
      {formattedTitle && (
        <title>{`${formattedTitle} ${renderActiveRegion()}`}</title>
      )}
      {description && (
        <meta name="description" content={interpolate(description, messages)} />
      )}
      {noIndex && !hasQueryParams && (
        <meta name="robots" content="noindex,nofollow" />
      )}
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@sendwaveapp" />
      {title && (
        <meta name="twitter:title" content={interpolate(title, messages)} />
      )}
      {description && (
        <meta
          name="twitter:description"
          content={interpolate(description, messages)}
        />
      )}
      <meta name="twitter:creator" content="@sendwaveapp" />
      {title && (
        <meta property="og:title" content={interpolate(title, messages)} />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${originUri}/${locale}${pathname}`} />
      {description && (
        <meta
          property="og:description"
          content={interpolate(title, messages)}
        />
      )}
      <meta property="og:site_name" content={SENDWAVE} />

      {schemaMarkups.map(({ schema, testId }) => (
        <script
          key={`${testId} - ${JSON.stringify(schema)}`}
          type="application/ld+json"
          data-testid={testId}
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      ))}

      {availableLanguageAlternates?.map(({ href, hreflang }) => (
        <link
          key={`${href}-${hreflang}`}
          rel="alternate"
          hrefLang={hreflang}
          href={href}
        />
      ))}
    </Head>
  );
};
