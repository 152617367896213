import { BLOCKS } from '@contentful/rich-text-types';
import { Box, Typography } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { MuiModal } from '@wr/web-ui';
import React, { useState } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';
import { useStyles } from './faq-popular-questions.styles';
import { FaqPopularQuestionsProps } from './faq-popular-questions.types';

export const FaqPopularQuestions: React.FC<FaqPopularQuestionsProps> = ({
  title,
  questions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<contentfulSchema.FaqItem>();
  const classes = useStyles();

  const handleModalOpen = (modalContent?: contentfulSchema.FaqItem) => {
    if (!modalContent) return;
    setContent(modalContent);
    setIsOpen(true);
  };

  return (
    <section data-testid="faq-popular-questions">
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      {questions?.items.map(question => (
        <Box
          key={question?.sys.id}
          data-testid={`faq-popular-questions-${question?.sys.id}`}
          className={classes.questionContainer}
          onClick={() => handleModalOpen(question as contentfulSchema.FaqItem)}
        >
          <Typography className={classes.questionLink}>
            {question?.title}
          </Typography>
        </Box>
      ))}
      <MuiModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={classes.modal}
      >
        <Box>
          <Typography className={classes.modalTitle} variant="h3">
            {content?.title}
          </Typography>
          {content?.body && (
            <RichTextContentRenderer
              json={content.body?.json}
              links={content.body.links as RichTextProps['links']}
              options={{
                [BLOCKS.PARAGRAPH]: {
                  paragraph: true,
                  variant: 'body2',
                },
                [BLOCKS.LIST_ITEM]: {
                  variant: 'body2',
                },
              }}
            />
          )}
        </Box>
      </MuiModal>
    </section>
  );
};
