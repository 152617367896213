import { Input, Typography } from '@mui/material';
import {
  DROPDOWN,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import { Container } from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useFaqsStore } from '@/state';

import { HeaderSelect } from '../../../ui/src/components/header/header-select';
import { useStyles } from './faq-hero.styles';
import { FaqHeroProps } from './faq-hero.types';

export const FaqHero: React.FC<FaqHeroProps> = ({
  heading,
  search,
  sendToText,
  sys,
  textColor,
  allCountries,
  primaryBackgroundColor,
  secondaryBackgroundColor,
}) => {
  const classes = useStyles({
    textColor,
    primaryBackgroundColor,
    secondaryBackgroundColor,
  });
  const router = useRouter();
  const [
    selectedCountry,
    faqCountries,
    setSelectedCountry,
    searchTerm,
    setSearchTerm,
  ] = useFaqsStore(state => [
    state.selectedCountry,
    state.faqCountries,
    state.setSelectedCountry,
    state.searchTerm,
    state.setSearchTerm,
  ]);
  const sendToCountryParam = router.query.to;
  const countrySwitcherDataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    dropdownName: DROPDOWN.COUNTRY_SELECTOR,
  };

  useEffect(() => {
    router.push(
      {
        pathname: '/support',
        query: {
          ...(selectedCountry && { to: selectedCountry }),
        },
      },
      undefined,
      { shallow: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, sendToCountryParam, setSelectedCountry]);

  useEffect(() => {
    if (sendToCountryParam) {
      setSelectedCountry(sendToCountryParam as string);
    }
  }, [sendToCountryParam, setSelectedCountry]);

  const onCountryOpen = () => {
    pushToDataLayer({
      ...countrySwitcherDataLayerProps,
      interactionName: INTERACTION_NAME.DROPDOWN_OPEN,
    });
  };

  return (
    <section className={classes.heroSection} data-testid={`faq-hero-${sys.id}`}>
      <Container className={classes.heroSectionContainer}>
        <Typography variant="h1" className={classes.heroHeading}>
          {heading}
        </Typography>

        {!!search && (
          <div className={classes.heroSearchBarWrapper}>
            <Input
              className={classes.heroSearchBar}
              placeholder={search.inputLabel || ''}
              value={searchTerm || ''}
              onChange={e => {
                setSearchTerm(e.target.value);
              }}
              data-testid="faq-hero-search"
            />
          </div>
        )}

        <div className={classes.heroSendCountrySection}>
          <Typography variant="body1" className={classes.heroSendCountryText}>
            {sendToText}
          </Typography>
          <HeaderSelect
            headerVariant="light"
            name="faq-country-selector"
            color={'primary'}
            onOpen={onCountryOpen}
            className={classes.heroHeaderSelect}
            onChange={selectedCountry => {
              pushToDataLayer({
                ...countrySwitcherDataLayerProps,
                interactionName: INTERACTION_NAME.DROPDOWN_SELECTION,
                dropdownValue: selectedCountry || '',
              });
              setSelectedCountry(selectedCountry);
            }}
            value={selectedCountry || ''}
            options={[
              {
                value: '',
                label: allCountries || '',
              },
              ...(faqCountries
                ?.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
                ?.map(country => ({
                  value: country.code || '',
                  label: country.name || '',
                })) || []),
            ]}
            data-testid="country-selector"
          />
        </div>
      </Container>
    </section>
  );
};
