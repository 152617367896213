import { BLOCKS } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import { FAQModule as UIFAQModule } from '@wr/web-ui';
import Head from 'next/head';

import { getFAQPageSchemaMarkup } from '@/components/faq-module/faq-module.utils';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Button, ButtonProps } from '../button';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';

export const FaqModule: React.FC<contentfulSchema.FaqModuleFragment> = ({
  sys,
  __typename,
  name,
  title,
  subheading,
  image,
  questions,
  ctaButton,
}) => {
  const schemaMarkup = getFAQPageSchemaMarkup(questions);

  return (
    <>
      {schemaMarkup && (
        <Head>
          <script id={sys.id} type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        </Head>
      )}
      <UIFAQModule<ButtonProps, RichTextProps>
        name={name || ''}
        title={title || ''}
        subheading={subheading || ''}
        image={
          image
            ? {
                ...image,
                style: {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                },
              }
            : null
        }
        ctaButton={{
          ...ctaButton,
          children: ctaButton?.label,
          color: 'secondary',
          variant: 'contained',
        }}
        questions={
          questions?.items.map(item => ({
            description: item?.description?.json
              ? {
                  ...item.description,
                  options: {
                    [BLOCKS.PARAGRAPH]: {
                      color: 'textSecondary',
                    },
                  },
                }
              : null,
            name: item?.name || '',
            title: item?.title || '',
            containerProps: buildContentfulSidekickAttributes(
              item?.sys.id,
              item?.__typename,
              item?.name,
            ),
          })) || []
        }
        RichTextComponent={RichTextContentRenderer}
        LinkComponent={Button}
        containerProps={buildContentfulSidekickAttributes(
          sys.id,
          __typename,
          name,
        )}
      />
    </>
  );
};
