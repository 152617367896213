import { contentfulSchema } from '@wr/web-shared';

import { contentfulClient, logger } from '@/utils';

export const searchFaqItems = async (
  searchTerm: string,
  locale: string,
  preview?: boolean,
): Promise<contentfulSchema.SearchFaqItemsQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.SearchFaqItemsQuery>({
      query: contentfulSchema.SearchFaqItems,
      variables: {
        searchTerm,
        locale,
        preview,
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `SearchFaqItems request failed`);

    throw {
      name: 'SearchFaqItemsQuery',
      message: error,
    };
  }
};
