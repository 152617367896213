import ExpandMoreIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionSummary, CircularProgress, Typography } from '@mui/material';
import { Accordion, Container as UIContainer, Image } from '@wr/web-ui';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '@/context';
import { useAccordionToggle } from '@/hooks';
import { searchFaqItems } from '@/services/contentful/faq/faq.service';
import { useFaqsStore } from '@/state';
import { Faq } from '@/state/sendwave/faq/faq.types';

import { Button } from '../button';
import { FaqPopularQuestions } from '../faq-popular-questions';
import { FaqAccordionDetails } from './faq-accordion-details';
import { useStyles } from './faq-section.styles';
import { FaqSectionModuleProps } from './faq-section.types';

export const FaqSection: React.FC<FaqSectionModuleProps> = ({
  title,
  clearText,
  resultsForText,
  emptySearchText,
  popularQuestions,
}) => {
  const { locale } = useContext(AppContext);
  const [faqTopics, searchTerm, clearFilters] = useFaqsStore(state => [
    state.faqTopics,
    state.searchTerm,
    state.clearFilters,
  ]);

  const [searchResults, setSearchResults] = useState<Faq[] | undefined>();

  const getSearchResults = (searchTerm: string) => {
    searchFaqItems(searchTerm, locale).then(contentfulFaqs => {
      const faqs: Faq[] = [];
      contentfulFaqs?.faqItemCollection?.items.forEach(faq => {
        if (!faq) return;

        faqs.push({
          id: faq.sys.id,
          name: faq.name,
          title: faq.title,
          body: faq.body,
          countries: [],
        });
      });

      setSearchResults(faqs);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchResults = useCallback(
    debounce(getSearchResults, 700),
    [],
  );

  useEffect(() => {
    setSearchResults(undefined);
    if (searchTerm) {
      debounceSearchResults(searchTerm);
    }
  }, [debounceSearchResults, searchTerm]);

  const classes = useStyles({ backgroundColor: '#fff' });

  const [expandedPanel, setExpandedPanel] = useState<string | undefined>();
  const [subExpandedPanel, setSubExpandedPanel] = useState<
    string | undefined
  >();

  const { handleSubPanelIcon, handleToggleAccordion } = useAccordionToggle(
    expandedPanel,
    subExpandedPanel,
  );

  return (
    <section className={classes.sectionWrapper} data-testid="faq-section">
      <UIContainer className={classes.gridWrapper}>
        <Typography
          className={classes.title}
          variant="h2"
          data-testid="faq-section-title"
        >
          {searchTerm ? (
            searchResults ? (
              <span>{`${
                searchResults?.length || 0
              } ${resultsForText} '${searchTerm}'`}</span>
            ) : null
          ) : (
            title
          )}
          {searchTerm && searchResults && (
            <Button
              data-testid="search-clear"
              variant="text"
              color={'primary'}
              onClick={clearFilters}
              className={classes.clearSearchTerm}
            >
              {clearText}
            </Button>
          )}
        </Typography>
        {searchTerm && !searchResults && (
          <div className={classes.searchResultsEmpty}>
            <CircularProgress />
          </div>
        )}

        {searchTerm && (
          <>
            {searchResults?.length === 0 && (
              <div className={classes.searchResultsEmpty}>
                {emptySearchText}
              </div>
            )}

            {searchResults && searchResults.length > 0 && (
              <div>
                <div className={classes.searchResultsWrapper}>
                  <FaqAccordionDetails
                    faqs={searchResults}
                    classes={classes}
                    handleToggleAccordion={handleToggleAccordion}
                    handleSubPanelIcon={handleSubPanelIcon}
                    subExpandedPanel={subExpandedPanel}
                    setSubExpandedPanel={setSubExpandedPanel}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {!searchTerm && (
          <div className={classes.topicsWrapper}>
            {faqTopics
              ?.filter(topic => topic.faqs.length > 0)
              ?.map(topic => {
                const panelIndex = `panel_${topic.id}`;

                return (
                  <Accordion
                    className={classes.accordion}
                    data-testid={`faq-topics-module-item-${topic.id}`}
                    expanded={expandedPanel === panelIndex}
                    onChange={() =>
                      handleToggleAccordion(setExpandedPanel, panelIndex)
                    }
                    key={`topic-${topic.id}`}
                  >
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Image
                        {...topic.icon}
                        className={classes.accordionSummaryAvatar}
                      />
                      <div>
                        <Typography
                          variant="h3"
                          className={classes.accordionSummaryTitle}
                        >
                          {topic.title} ({topic.faqs.length})
                        </Typography>
                      </div>
                    </AccordionSummary>

                    <FaqAccordionDetails
                      faqs={topic.faqs}
                      classes={classes}
                      handleToggleAccordion={handleToggleAccordion}
                      handleSubPanelIcon={handleSubPanelIcon}
                      subExpandedPanel={subExpandedPanel}
                      setSubExpandedPanel={setSubExpandedPanel}
                    />
                  </Accordion>
                );
              })}
          </div>
        )}

        <div className={classes.questionsWrapper}>
          {popularQuestions && <FaqPopularQuestions {...popularQuestions} />}
        </div>
      </UIContainer>
    </section>
  );
};
