import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { contentfulSchema } from '@wr/web-shared';

// https://developers.google.com/search/docs/advanced/structured-data/faqpage
export const getFAQPageSchemaMarkup = (
  questions: contentfulSchema.FaqModuleFragment['questions'],
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity':
      questions?.items.map(question => ({
        '@type': 'Question',
        'name': question?.title,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': question?.description?.json
            ? documentToPlainTextString(question.description.json)
            : '',
        },
      })) || [],
  };
};
