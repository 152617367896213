import { Box, Modal } from '@mui/material';
import React from 'react';

import { useStyles } from './modal.styles';
import { MuiModalProps } from './modal.types';

export const MuiModal: React.FC<MuiModalProps> = ({
  children,
  ...modalProps
}) => {
  const classes = useStyles();

  return (
    <Modal {...modalProps}>
      <Box className={classes.container}>{children}</Box>
    </Modal>
  );
};
