import { CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS } from './core/core.constants';

export const getInputValue = (value: string): string | null => {
  const isValueEndsWithSingleDot =
    value.split('.').length - 1 === 1 && value.endsWith('.');

  if (CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS.test(value)) {
    return value;
  }
  if (isValueEndsWithSingleDot) {
    const replacedValue = value.replace(/^0+/, '');

    if (replacedValue.length === 1 && replacedValue === '.') {
      return '0.';
    }

    return replacedValue;
  }

  return null;
};

export const getUrlOverride = (): string | null => {
  const path = window.location.pathname;

  const countryPageMatch = path.match(
    /^\/[a-z]{2}\/countries\/[a-z]+\/([a-z]{2})/i,
  );
  if (countryPageMatch) {
    return countryPageMatch[1].toUpperCase();
  }

  const localeMatch = path.match(/^\/([a-z]{2}-[a-z]{2})/);
  if (localeMatch) {
    return localeMatch[1].split('-')[1].toUpperCase();
  }

  const oldCountryPageMatch = path.match(/^\/countries\/([a-z]+)\/([a-z]{2})/i);
  if (oldCountryPageMatch) {
    return oldCountryPageMatch[2].toUpperCase();
  }

  return null;
};
